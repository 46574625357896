<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon v-if="status === 'En edition'" title="BR vers Stock" icon="ServerIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmTransfertVersStock" />
      <template v-if="status === 'En edition' && activeUserInfos.privileges.find(item => item === 'nouveau_br')&&activeUserInfos.privileges.find(item => item === 'update')">
        <feather-icon v-if="pourcentageTransfert < 100" title="Editer BR" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      </template>
      <template v-if="status === 'En edition' && activeUserInfos.privileges.find(item => item === 'nouveau_br')&&activeUserInfos.privileges.find(item => item === 'delete')">
        <feather-icon v-if="pourcentageTransfert == 0" title="Supprimer BR" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
      </template>
      <feather-icon title="Voir BR" icon="EyeIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="goToDetailFacture" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  props: ['details', 'id', 'CodeCmde', 'status'],
  data () {
    return {
      activeUserInfos: null
    }
  },
  computed:{
    pourcentageTransfert () {
      const details = this.details
      const nbreItem = details.length
      const transfertItem = details.filter((i) => i.StockTransfere === true)
      /* console.log(nbreItem)
      console.log(transfertItem)
      console.log(transfertItem.length)
      console.log(((transfertItem.length * 100) / nbreItem)) */
      return transfertItem.length ?  ((transfertItem.length * 100) / nbreItem) : 0
    }
  },
  methods: {
    goToDetailFacture () {
      this.$router.push(`/apps/commandefourn/view/${this.id}`).catch(() => {})
    },

    editRecord () {
      this.$router.push(`/apps/commandefourn/edit/${this.id}`).catch(() => {})
    },
    confirmTransfertVersStock () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Transfert BR',
        text: `Vous allez transferer le Bon ${this.CodeCmde} vers l'officine de destination, Une action de validation de l'officine est neccessaire pour terminer le transfert vers le stock !`,
        acceptText: 'Vers stock',
        accept: this.commandeVersStock
      })
    },
    commandeVersStock () {
      const payload = {
        id: this.id,
        IdUser: this.activeUserInfos._id
      }
      this.$store.dispatch('commande_fourn/transfertCommandeFournisseurInToStock', payload)
        .then(() => {
          /* this.$router.push('/apps/stock').catch(() => {}) */
          this.getBonReceptionByOfficine(this.activeUserInfos.IdOfficine)
          this.$vs.notify({
            color: 'success',
            title: 'BR vers Stock',
            text: 'Le BR   a bien été ajouté au stock stock'
          })
        })
        .catch((err) => { console.log(err) })
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Vous allez supprimé le bon de réception ${this.CodeCmde}`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$store.dispatch('commande_fourn/deleteCommandeFournisseur', this.id)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Bon de réception Supprimé',
        text: 'Le bon selectionné a bien été supprimé'
      })
    },
    getBonReceptionByOfficine (id) {
      const payload = {
        officineId: id,
        Page: this.page
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_fourn/getBonReceptionByOfficine', payload)
        .then(() => {
        })
        .catch(err => { console.log(err) })
        .finally(() => {
          this.$vs.loading.close()
        })

    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.activeUserInfo()
  }
}
</script>
